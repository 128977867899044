import { initForms } from './components/initForms';
import { initStickyTableHeader } from './components/initStickyTableHeader';
import { initAccordion } from './components/accordion';
import { initJivosite } from './components/initJivosite';
import { InitAnimationSearch } from './components/InitAnimationSearch';
import { initOpenCreateAccountModal, initOpenCreateAccountModalFull, initSearch } from './components/modal/initModal';
import { Modal } from './components/modal/Modal';
import { initLangSwitcher } from './components/initLangSwitcher';
import { initScrollToAnchor } from './components/initScrollToAnchor';
import { initMobileFeatureMenu } from './components/initMobileFeatureMenu';
import { initRenderPricesPage } from './components/initRenderPricesPage';
import { initMobileMenu } from './components/initMobileMenu';
import { initSliders } from './components/initSliders';
import { initScrollLocker } from './assets/scrollLocker';
import { initStickyBanner } from './components/initStickyBanner';
import { initBannerTimer } from './components/initBannerTimer';
import { initLazyLoadFonts } from './components/initLazyLoadFonts';
import { initDeclineModal } from './components/modal/initDeclineModal';
import { initFunnelHash } from '../../../../Common/Resources/src_front/js/components/initFunnelHash';
import { initHashEmail } from '../../../../Common/Resources/src_front/js/components/initHashEmail';
import { initEmailAutocompleteDropdown } from '../../../../Common/Resources/templates/components/EmailAutocompleteDropdown/initEmailAutocompleteDropdown';
import { initRippleAnimation } from '../../../../Common/Resources/templates/components/RippleAnimation/initRippleAnimation';
import { initChunkPageBeforeCheckout } from './components/initChunkPageBeforeCheckout';
import { initPageLoader } from '../../../../Common/Resources/templates/components/PageLoader/initPageLoader';
import { initDetectionOrganicTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionOrganicTraffic';
import { initDetectionReferrerTraffic } from '../../../../Common/Resources/src_front/js/components/initDetectionReferrerTraffic';
import { initMixpannel } from '../../../../Common/Resources/src_front/js/mixpannel/initMixpannel';
import { initSliderTestimonials } from './components/initTestimonialsSlider';

document.addEventListener('DOMContentLoaded', function () {
    initPageLoader();
    initDetectionReferrerTraffic();
    initFunnelHash();
    initDetectionOrganicTraffic();
    initChunkPageBeforeCheckout();
    initLazyLoadFonts();
    initHashEmail();
    initScrollLocker();
    const animationSearch = new InitAnimationSearch();
    const modal = new Modal();
    initForms(modal);
    initStickyTableHeader();
    initAccordion();
    initSliders();
    initJivosite();
    initOpenCreateAccountModal(modal);
    initSearch(modal, animationSearch);
    initLangSwitcher();
    initMobileMenu();
    initScrollToAnchor();
    initMobileFeatureMenu();
    initRenderPricesPage();
    initBannerTimer();
    initStickyBanner();
    initOpenCreateAccountModalFull(modal);
    initDeclineModal();
    initEmailAutocompleteDropdown();
    initRippleAnimation();
    initMixpannel();
    initSliderTestimonials();

    // old page price, need delete with price.html.twig, prices_a.html.twig
    // oldScriptPrisePage();
});

// If the browser has cached the page
window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        window.location.reload();
    }
});
